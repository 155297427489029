@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

/*****************/
/* Generic Stuff */
/*****************/
.mulish {
  font-family: "Mulish", sans-serif;
}
.opensane {
  font-family: "Open Sans", sans-serif;
}
.plain {
  font-family: "Roboto", sans-serif;
}
.serif {
  font-family: Georgia;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.theme-preview {
  position: relative;
  width: 100%;
  height: 70vh;
  max-height: 800px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  padding: 40px 40px;
}

.preview-title-font {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  color: #f9f9f9;
}
.preview-subtitle-font {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 10px;
  border-radius: 0px !important;
}
.preview-button {
  background: #f9f9f9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: inline-block;
  text-transform: none !important;
  padding: 5px 10px !important;
}
.left .preview-button {
  align-self: baseline;
}
.center .preview-button {
  align-self: center;
}
.right .preview-button {
  align-self: flex-end;
}
.theme-preview .MuiButton-label {
  font-size: 14px;
}
.top-left-container {
  position: absolute;
  top: 0;
  left: 0;
}
.preview-top-icon {
  max-width: 75px;
  max-height: 25px;
  height: auto;
  margin: 10px;
}
.how-it-works {
  text-decoration: underline;
  margin-top: 5px;
}
.background-preview-tile {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  cursor: pointer;
}
.background-preview-tile.custom {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
}
.tile-selected {
  border: solid 2px #62cb88 !important;
}

.font-preview-tile {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  border: solid 1px gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

/*********/
/* Tangerine */
/*********/
.background-preview-tile.tangerine {
  background: linear-gradient(121.94deg, #f6d365 0.42%, #fda085 100%);
}
.theme-preview.tangerine {
  color: white;
  background: linear-gradient(121.94deg, #f6d365 0.42%, #fda085 100%);
}
.preview-title-font.tangerine {
  color: #f9f9f9;
}
.preview-subtitle-font.tangerine {
  color: #c57b57;
}
.preview-button.tangerine {
  background: #f9f9f9;
}
.preview-button.tangerine:hover {
  background: #f9f9f9 !important;
}
.tangerine .MuiButton-label {
  color: #c57b57 !important;
}

/**********/
/* Lavender */
/**********/
.background-preview-tile.lavender {
  background: linear-gradient(121.94deg, #a18cd1 0.42%, #fbc2eb 100%);
}
.theme-preview.lavender {
  color: white;
  background: linear-gradient(121.94deg, #a18cd1 0.42%, #fbc2eb 100%);
}
.preview-title-font.lavender {
  color: #f9f9f9;
}
.preview-subtitle-font.lavender {
  color: #856084;
}
.preview-button.lavender {
  background: #f9f9f9;
}
.preview-button.lavender:hover {
  background: #f9f9f9 !important;
}
.lavender .MuiButton-label {
  color: #a18cd1 !important;
}

/* Ocean */
.background-preview-tile.ocean {
  background: linear-gradient(121.94deg, #a1c4fd 0.42%, #c2e9fb 100%);
}
.theme-preview.ocean {
  color: white;
  background: linear-gradient(121.94deg, #a1c4fd 0.42%, #c2e9fb 100%);
}
.preview-title-font.ocean {
  color: #f9f9f9;
}
.preview-subtitle-font.ocean {
  color: #718abf;
}
.preview-button.ocean {
  background: #f9f9f9;
}
.preview-button.ocean:hover {
  background: #f9f9f9 !important;
}
.ocean .MuiButton-label {
  color: #a1c4fd !important;
}

/*********/
/* dark */
/*********/
.background-preview-tile.dark {
  background: linear-gradient(
    121.94deg,
    rgba(0, 0, 0, 0.9) 0.42%,
    #2e2e2e 100%
  );
}
.theme-preview.dark {
  color: white;
  background: linear-gradient(
    121.94deg,
    rgba(0, 0, 0, 0.9) 0.42%,
    #2e2e2e 100%
  );
}
.preview-title-font.dark {
  color: #f9f9f9;
}
.preview-subtitle-font.dark {
  color: #8e8e8e;
}
.preview-button.dark {
  background: #f9f9f9;
}
.preview-button.dark:hover {
  background: #f9f9f9 !important;
}
.dark .MuiButton-label {
  color: #2e2e2e !important;
}

/*********/
/* Light */
/*********/
.background-preview-tile.light {
  background: linear-gradient(121.94deg, #ebedee 0.42%, #fdfbfb 100%);
}
.theme-preview.light {
  color: #878c8f;
  background: linear-gradient(121.94deg, #ebedee 0.42%, #fdfbfb 100%);
}
.preview-title-font.light {
  color: #232529;
}
.preview-subtitle-font.light {
  color: #878c8f;
}
.preview-button.light {
  background: #f9f9f9;
}
.preview-button.light:hover {
  background: #f9f9f9 !important;
}
.light .MuiButton-label {
  color: #2e2e2e !important;
}

/******************/
/* Textured Light */
/******************/
.background-preview-tile.textured-light {
  background: url("./assets/img/textured-tile.png");
  background-repeat: no-repeat;
  background-size: auto;
}
.theme-preview.textured-light {
  color: #878c8f;
  background: url("./assets/img/textured.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}
.preview-title-font.textured-light {
  color: #232529;
}
.preview-subtitle-font.textured-light {
  color: #878c8f;
}
.preview-button.textured-light {
  background: #f9f9f9;
}
.preview-button.textured-light:hover {
  background: #f9f9f9 !important;
}
.textured-light .MuiButton-label {
  color: #2e2e2e !important;
}

/* PHONIC MINT */
.background-preview-tile.mint {
  background: linear-gradient(121.94deg, #2ac79f 0.42%, #6ac2ac 100%);
}
.theme-preview.mint {
  background: linear-gradient(121.94deg, #2ac79f 0.42%, #6ac2ac 100%);
}
.preview-title-font.mint {
  color: #ffffff;
}
.preview-subtitle-font.mint {
  color: #0b8062;
}
.preview-button.mint {
  color: #2ac79f !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.preview-button.mint:hover {
  color: #2ac79f !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.mint .MuiButton-label {
  color: #2ac79f !important;
}

/* TURQUOISE */
.background-preview-tile.turquoise {
  background: linear-gradient(121.94deg, #96deda 0.42%, #50c9c3 100%);
}
.theme-preview.turquoise {
  background: linear-gradient(121.94deg, #96deda 0.42%, #50c9c3 100%);
}
.preview-title-font.turquoise {
  color: #ffffff;
}
.preview-subtitle-font.turquoise {
  color: #1f8b86;
}
.preview-button.turquoise {
  color: #50c9c3 !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.preview-button.turquoise:hover {
  color: #50c9c3 !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.turquoise .MuiButton-label {
  color: #2ac79f !important;
}

/* PASSIONFRUIT */
.background-preview-tile.passionfruit {
  background: linear-gradient(121.94deg, #ff758c 0.42%, #ff7eb3 100%);
}
.theme-preview.passionfruit {
  color: #ca3271;
  background: linear-gradient(121.94deg, #ff758c 0.42%, #ff7eb3 100%);
}
.preview-title-font.passionfruit {
  color: #ffffff;
}
.preview-subtitle-font.passionfruit {
  color: #ca3271;
}
.preview-button.passionfruit {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.preview-button.passionfruit:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.passionfruit .MuiButton-label {
  color: #ff758c !important;
}

/* MIDNIGHT */
.background-preview-tile.midnight {
  background: linear-gradient(121.94deg, #2b5876 0.42%, #4e4376 100%);
}
.theme-preview.midnight {
  color: #cecece;
  background: linear-gradient(121.94deg, #2b5876 0.42%, #4e4376 100%);
}
.preview-title-font.midnight {
  color: #ffffff;
}
.preview-subtitle-font.midnight {
  color: #cecece;
}
.preview-button.midnight {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.preview-button.midnight:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #f9f9f9 !important;
}
.midnight .MuiButton-label {
  color: #2b5876 !important;
}

/* COFFEE */
.background-preview-tile.coffee {
  background: linear-gradient(121.94deg, #fdfcfb 0.42%, #e2d1c3 100%);
}
.theme-preview.coffee {
  color: #878c8f;
  background: linear-gradient(121.94deg, #fdfcfb 0.42%, #e2d1c3 100%);
}
.preview-title-font.coffee {
  color: #232529;
}
.preview-subtitle-font.coffee {
  color: #878c8f;
}
.preview-button.coffee {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #a57650 !important;
}
.preview-button.coffee:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #a57650 !important;
}
.coffee .MuiButton-label {
  color: #ffffff !important;
}

/* Lime */
.background-preview-tile.lime {
  background: linear-gradient(121.94deg, #d2f484 0.42%, #96e6a1 100%);
}
