@import "./variables";

// MUI Chips.
.template-chip.MuiChip-sizeSmall {
  background: $phonic-green-transparent;
  color: $off-black;
  margin-top: 5px;
  margin-bottom: 5px;
}
.search-result .MuiChip-sizeSmall {
  margin-right: 5px;
  background: gray;
  color: white;
}
.search-result .completed.MuiChip-sizeSmall {
  background-color: $phonic-green;
  color: white;
}
.search-result .pending.MuiChip-sizeSmall {
  background-color: lightslategray;
  color: white;
}
