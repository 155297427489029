@import "./variables";
@import "./utils";
@import "./editor";

#deprecation-warning {
  background-color: #e52121;
  padding: 10px 20px;

  p {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    margin-right: 30px;
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

#app-navbar {
  width: 100%;
  height: 54px;
  background-color: white;
  border-bottom: solid 1px lightgray;
  padding-right: 30px;
  padding-left: 30px;

  .sidebar-toggle {
    display: none;
    height: 100%;
  }

  @media (max-width: 991px) {
    .navbar-links {
      display: none;
    }

    .sidebar-toggle {
      display: flex;
      float: right;
    }
  }
}

h1.page-title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  align-self: center;
  color: $off-black;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  font-family: "Inter";
  text-transform: capitalize;
  color: #9da7bb;

  &.page-title-surveys {
    @media (max-width: 500px) {
      display: none;
    }
  }
}

h1.file-title {
  font-size: 28px;
  text-align: left;
  align-self: center;
  color: $off-black;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 0px;
  font-family: "Inter";
}

h3.file-title {
  font-size: 14px;
  text-align: left;
  align-self: center;
  color: #9da7bb;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: medium;
  margin-left: 0px;
  font-family: "Inter";
}

.filter-popover {
  width: 300px;
  padding: 20px;

  h3 {
    font-size: 16px;
    margin: 5px 5px 10px 0px;
  }

  h4 {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  .section {
    margin-bottom: 10px;
  }
}

#response-screen {
  margin: -0px 15px;

  .selection-results {
    .selection-result {
      word-wrap: break-word;
      hyphens: auto;
      display: flex;
      align-items: center;
      background: #fafafa;
      border-radius: 5px;
      border: 1px solid lightgray;
      padding: 5px 10px;
      margin: 5px;
      max-width: 180px;
    }
  }
}

#response-header.card {
  margin-bottom: 20px;
}

#response-table {
  .bookmark {
    color: gray;
    cursor: pointer;
  }
}

.add-survey-data {
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

/* Replace Bootstrap classes */

.b-row {
  margin-right: 15px;
  display: flex;
}

.b-col-6 {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.bookmark-browser-paper {
  border: "none";
  background-color: "#f9f9f9";
  border-radius: 0;
  padding: 16px;
}

.report-wrapper {
  background-color: #ffffff;
  padding-bottom: 20px;
  min-height: 100vh;
}

.styled-textarea {
  border-radius: 4px;
  width: 100%;
}

.create-report-button {
  margin-top: 16px;
  margin-bottom: 8px;
  float: right;
}

.position-relative {
  position: relative;
}

.content-block-wrapper {
  padding: 8px;
  width: 100%;
}

.empty {
  color: #9e9e9e;
}

.report-banner {
  background: #1d1c29;
}

.markdown-editable {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 4px;

  &:hover {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.inline-center {
  display: inline-block;
  text-align: center;
}

#response-dialog {
  padding: 30px;
  min-height: 60vh;

  h3 {
    margin: 10px 0px;
  }

  p {
    font-size: 16px;
  }
}

#play-information {
  flex-grow: 1;
  padding: 30px;
  min-height: 60vh;

  h3 {
    margin: 0px 0px 20px 0px;
  }

  p {
    font-family: "Open Sans";
  }

  .media {
    margin-bottom: 20px;
  }
}

.screen-margin {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 100%;
}

#logic-container {
  background: #fafafa;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  position: relative;

  .logic-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: 100%;
  }

  .logic-long {
    min-width: 120px;
    margin: 5px;
  }

  .logic-short {
    min-width: 50px;
    margin: 5px;
  }

  .logic-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 5px;
    padding: 0px 10px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #639e8f;
    color: rgb(55, 55, 55);
    background: #639e8f43;

    &.alternate {
      border: 1px solid #1d1c29;
      background: #1d1c2948;
    }
  }

  .remove-button {
    position: absolute;
    top: 5px;
    right: 10px;
    color: gray;
    cursor: pointer;
  }

  .remove-button:hover {
    opacity: 0.5;
  }
}

.translation-file-line {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.translation-file-text {
  flex: 1;
  margin: 5px;
  margin-right: 10px;
}

.translation-file-button {
  margin-top: 10px;
  height: 18px;
}

.gray-outlined-button.MuiButton-outlined>.MuiButton-label {
  color: gray !important;
}

#translation-container {
  background: #fafafa;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  position: relative;

  .translation-line {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }

  .translation-long {
    flex: 1;
    margin: 5px;
    margin-right: 25px;
  }

  .translation-short {
    min-width: 100px;
    margin: 5px;
  }

  .translation-flex {
    flex: 1;
    margin: 5px;
  }

  .translation-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 5px;
    padding: 0px 10px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #639e8f;
    color: rgb(55, 55, 55);
    background: #639e8f43;

    &.alternate {
      border: 1px solid #1d1c29;
      background: #1d1c2948;
    }
  }

  .remove-button {
    position: absolute;
    top: 5px;
    right: 10px;
    color: gray;
    cursor: pointer;
  }

  .remove-button:hover {
    opacity: 0.5;
  }

  .add-translation-lang {
    margin: 5px;
    width: 10px;
    float: right;
  }
}

.custom-likert-label-input {
  margin-right: 10px !important;
  min-width: 60% !important;
}

.recode-likert-label-input {
  margin-left: 10px !important;
  min-width: 20% !important;
}

#question-picker-dialog {
  min-width: 400px;
  margin: 20px;

  .title {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

#question-types {
  height: 400px;

  .bottom-spacing {
    padding: 25px;
  }

  .quesiton-type-tile {
    width: 105px;
    height: 105px;
    border: solid 1px lightgray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    padding: 10px;
  }

  .quesiton-type-tile:hover {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
  }

  .question-type-tile-text {
    font-size: 14px;
    text-align: center;
    color: gray;
  }

  .tile-section-text {
    padding-top: 10px;
    padding-left: 10px;
    font-size: 14px;
    color: gray;
  }

  .question-type-tile-icon {
    font-size: 22px;
    margin: 4px;
    margin-bottom: 10px;
    text-align: center;
    color: gray;
  }

  .tile-section {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #fafafa;
    }

    .info {
      flex-grow: 1;
    }

    p {
      &.type-header {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }

      &.type-body {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}

#section-header {
  margin-bottom: 10px;
  width: 100%;
  text-align: center;

  .title {
    width: 75px;
    margin: auto;
    margin-top: -30px;
    z-index: 1000;
    color: gray;
    font-weight: bold;
  }

  .separator {
    width: 100%;
  }
}

#welcome-screen-builder {
  flex-grow: 1;
  justify-content: center;
  position: relative;

  &:hover {
    background: #fafafa;
  }

  .disabled {
    opacity: 0.3;
  }
}

.session-view {
  position: relative;

  hr {
    margin: 0px;
  }

  .scroller,
  &.scroller {
    overflow: scroll;

    /* Chrome & Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .scroller {
    max-height: calc(100vh - 280px);

    &.pagination-shown {
      max-height: calc(100vh - 300px);
    }
  }

  &.scroller {
    max-height: calc(100vh - 280px);

    &.pagination-shown {
      max-height: calc(100vh - 240px);
    }
  }

  .session-question {
    padding: 30px;
    display: flex;

    &.hover:hover {
      background: #fafafa;
      cursor: pointer;
    }
  }

  .respondent-title {
    display: inline-block;
    margin: 8px 6px;
  }

  .session-chevron {
    margin-top: -8px;
  }

  .response-text {
    color: black;
    font-size: 14px;
    margin: 12px 0px;
    font-weight: 300;
  }

  .audio-player {
    width: 100%;
    height: 40px;
  }

  .question-type {
    width: 40%;
    margin: 6px 0;
    float: left;
    margin-top: 18px;
  }

  .sentiment-container {
    width: 30%;
    float: right;
    margin-top: 2px;
  }

  .dataviz-container {
    height: 40px;
  }

  .question-identifier {
    margin-right: 20px;
    white-space: nowrap;
  }

  .question-content {
    width: 100%;

    h3 {
      font-size: 16px;
      margin: 0px 0px 10px 0px;
    }
  }
}

.response-title {
  color: #787878;
  font-size: 12px;
  font-weight: 300;
}

.report-header-padding {
  padding: 30px 0;
}

.nav-link {
  background: #424454;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 24px;

  &.active {
    background: #62cb88 !important;
  }
}

.nav-name {
  font-size: 11px !important;
  width: 50px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: -6px;
  margin-bottom: 15px;
}

.nav-logo {
  font-size: 24px;
  width: 40px;
  height: 40px;
}

.table-row:nth-of-type(odd) {
  background-color: white;
}

.create-report-title {
  font-size: 26px !important;
  font-weight: 300;
}

@media (max-width: 991px) {
  .session-view {
    margin-bottom: 10px;
  }
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#auth-screen {
  min-height: 100vh;
  background-color: #fafafa;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.08'%3E%3Cpolygon fill='%23ebeeec' points='800 100 0 200 0 800 1600 800 1600 200'/%3E%3Cpolygon fill='%23d9e4de' points='800 200 0 400 0 800 1600 800 1600 400'/%3E%3Cpolygon fill='%23c5ddcf' points='800 300 0 600 0 800 1600 800 1600 600'/%3E%3Cpolygon fill='%23afd8c1' points='1600 800 800 400 0 800'/%3E%3Cpolygon fill='%2397d5b1' points='1280 800 800 500 320 800'/%3E%3Cpolygon fill='%237cd4a2' points='533.3 800 1066.7 800 800 600'/%3E%3Cpolygon fill='%235fd692' points='684.1 800 914.3 800 800 700'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  h2 {
    font-weight: 800;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;

    a {
      color: $phonic-green;
      font-weight: bold;
    }

    &.clickable {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &.small {
      font-size: 12px;
    }
  }

  .MuiTextField-root {
    margin: 0;
  }
}

.login-button {
  &.MuiButton-contained {
    background-color: #28bf8f !important;
  }
}

.google-signin-button {
  justify-content: space-between;

  &.MuiButton-contained {
    background-color: white !important;
  }

  .MuiButton-label {
    color: black !important;
  }

  #google-icon {
    height: 20px;
    float: left;
  }
}

#survey-builder {
  min-height: calc(100vh - 55px);

  #tabs {
    background: white;
  }

  #editor {
    background: white;
    height: calc(100vh - 110px);
    overflow: scroll;
    overflow-x: hidden;
    border-right: 1px solid lightgray;
  }

  #preview {
    width: 100%;
    height: calc(100vh - 110px);
    border: none;
  }

  #saving-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100px;
    margin-right: 10px;
    color: gray;
  }

  #unpublished-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: hsl(0, 75%, 45%);
    font-size: 1.5rem;

    p {
      padding: 0 5px;
      font-size: 1rem;
      margin: auto;
    }
  }

  hr {
    border-top: 1px solid lightgray;
  }

  #header {
    width: 100%;
    position: relative;

    .action-buttons {
      display: flex;
      position: absolute;
      right: 30px;
      top: 7px;

      #duration-estimate {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 0 15px;
        margin-bottom: 0;
        margin-right: 10px;
        color: #707070;
        border: 1px solid #707070;
        border-radius: 8px;
      }

      #publish-button {
        background-color: #1d1c29 !important;
      }
    }
  }
}

.MuiButton-outlined.preview-outlined-button {
  margin-right: 10px;
  background-color: white !important;
  border: 1px solid $off-black;

  .MuiButton-label {
    color: $off-black;
  }

  &:hover {
    border: 1px solid $off-black;
  }
}

.phonic-outlined-button {
  background-color: white !important;
  border: 1px solid $off-black;

  .MuiButton-label {
    color: $off-black;
  }

  &.mr-10 {
    margin-right: 10px;
  }
}

.delete-item-button {
  background-color: rgb(181, 9, 9) !important;
  color: white !important;
}

.delete-item-button:hover {
  background-color: rgb(205, 13, 13) !important;
}

.builder-question-group {
  display: flex;

  .group-line {
    width: 7px;
    background: purple;
    border-radius: 5px;
  }

  .new-question-button {
    position: absolute;
    top: 35px;
    right: 20px;
    display: none;
    padding: 0px;
    min-width: 2rem;

    .MuiButton-label {
      font-weight: 600;
      color: #5a5c61;
    }
  }

  .group-content {
    flex-grow: 1;
    padding-top: 30px;

    .group-info-section {
      padding-left: 30px;

      .Mui-Overrides {
        &.text {
          width: 100%;

          &.bullets {
            background: none;
            border: none;
            font-size: 16px;
            line-height: 25px;
            font-weight: 300;
            font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
            padding: 0px;
            margin: 0px 0px 0px 0px;
          }

          &.MuiInputBase-multiline {
            width: 100%;
            padding: 0px;
            margin: 0px 0px 5px 0px;
          }

          .MuiInputBase-inputMultiline {
            background: none;
            border: none;
            font-size: 20px;
            line-height: 25px;
            font-weight: 300;
            font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
            padding: 0px;
            margin: 0px 0px 0px 0px;
          }

          &.sub {
            .MuiInputBase-inputMultiline {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.builder-question-group {
  position: relative;
  z-index: 1;

  &:hover {
    background: hsl(0, 0%, 97.5%);

    .new-question-button {
      display: inherit;
      z-index: 2;
    }
  }
}

.builder-question {
  position: relative;
  z-index: 1;

  &:hover {
    background: hsl(0, 0%, 95%);
  }

  &.darken {
    background: hsl(0, 0%, 95%);
  }

  margin-bottom: 5px;

  .condensed {
    display: flex;
    width: 100%;
    padding: 30px;

    .type-section {
      width: 60px;
      margin-right: 10px;
    }

    .info-section {
      flex-grow: 1;

      h3 {
        font-size: 20px;
        margin-top: 0px;
      }

      .editable {
        cursor: text;

        &:focus {
          outline-width: 0;
        }

        &:empty:before {
          content: attr(data-placeholder);
          color: lightgray;
        }
      }
    }

    .Mui-Overrides {
      &.text {
        width: 100%;

        &.bullets {
          background: none;
          border: none;
          font-size: 16px;
          line-height: 25px;
          font-weight: 300;
          font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
          padding: 0px;
          margin: 0px 0px 0px 0px;
        }

        &.MuiInputBase-multiline {
          width: 100%;
          padding: 0px;
          margin: 0px 0px 5px 0px;
        }

        .MuiInputBase-inputMultiline {
          background: none;
          border: none;
          font-size: 20px;
          line-height: 25px;
          font-weight: 300;
          font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
          padding: 0px;
          margin: 0px 0px 0px 0px;
        }

        &.sub {
          .MuiInputBase-inputMultiline {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }

    .option {
      display: flex;
    }
  }

  .options {
    padding-left: 20px;

    &:focus {
      outline-width: 0;
    }
  }
}

.theme-name {
  font-size: 1.7rem;
  margin: 0;
}

.stacked-bar {
  height: 15px;
  border-radius: 5px;
  background-color: #f5f9f9;
  overflow: hidden;
  display: flex;
}

.segment {
  height: 100%;
  display: inline-block;
}

.sentiment-label {
  display: flex;
  align-items: center;

  .key {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    border: 1px solid white;
  }

  p {
    margin: 0;
    font-size: 0.875rem;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }
}

.topic-card {
  cursor: pointer;
  padding: 16px;
  position: relative;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .hover-wrapper {
    display: none;
  }

  &:hover {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
    transition: 0ms;

    .hover-wrapper {
      display: block;
    }
  }
}

.pressed {
  border: 2px solid #51a871;
}

ul.dashed {
  list-style-type: none;
}

ul.dashed>li {
  text-indent: -5px;
}

ul.dashed>li:before {
  content: "-";
  text-indent: -5px;
  margin-right: 5px;
}

#question-settings {
  overflow: scroll;
  overflow-x: hidden;
  border-left: 1px solid lightgray;
  height: calc(100vh - 110px);
  position: relative;
  padding: 20px;
  background: white;

  h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.popup-container {
  min-width: 600px;

  &.small {
    min-width: 500px;
  }

  h2 {
    font-size: 20px;

    &.md {
      font-size: 28px;
    }

    &.lg {
      font-size: 34px;
    }
  }

  p {
    font-size: 12px;

    &.md {
      font-size: 14px;
    }

    &.lg {
      font-size: 16px;
    }
  }

  a {
    color: $phonic-green;
  }

  .align-center {
    display: flex;
    align-items: center;
  }
}

.qtype-tile {
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.shape-rect {
    width: 60px;
    padding: 2px 7px;
    font-size: 14px;
    font-weight: bold;
  }

  &.shape-square {
    height: 50px;
    width: 50px;
    font-size: 16px;
  }
}

.documentation-help {
  p {
    font-size: 12px;
    margin: 0 auto;
  }

  a {
    color: $phonic-green;
  }
}

.documentation-help.light {
  color: #9d9d9d;
}

.share-toggle-header {
  color: #606060;
}

.text-title {
  font-size: 22px;
}

.share-card {
  padding: 18px;
  height: 100%;

  &:hover {
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 40%);
    transition: 0ms;
    cursor: pointer;
  }

  >* {
    margin-left: auto;
    margin-right: auto;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    flex-direction: column;
    margin: 0;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
}

.share-card-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  margin-bottom: 8px;
}

.embed-icon {
  background-color: $phonic-blue;
  border-radius: 6px;
  width: 40;
  height: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-preview {
  position: relative;

  &.builder {
    margin-right: 16px;
    margin-left: 16px;
    height: 100%;
  }

  .preview-wrapper {
    @include flex(column);
    height: 100%;
    border-radius: 8px;

    iframe {
      width: 100%;
      overflow: auto;
      min-height: 600px;
      flex: 1;
    }
  }
}

.info-text {
  margin-bottom: 10px;

  p {
    font-size: 12px;
  }

  a {
    color: $phonic-green;
  }
}

@media (max-width: 600px) {
  #survey-builder {
    #preview {
      display: none;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.delete-container {
  display: none;

  &:hover {
    display: block;
  }
}

.chip-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  >* {
    margin-right: 8px;
  }
}

.entity-type {
  color: $subtitle-gray;
  text-transform: lowercase;
}

.Neutral.light {
  background-color: #d6d6d6;
}

.Positive.light {
  background-color: #d4ece6;
}

.Negative.light {
  background-color: #edd5d4;
}

.Mixed.light {
  background-color: #e8e4ba;
}

.bullets {
  margin-bottom: 15px;
}

.Mui-Overrides {
  &.font-dark {
    .MuiButton-label {
      color: rgb(34, 34, 34);
    }
  }

  &.outlined-dark {
    border: 1px solid $off-black;

    .MuiButton-label {
      color: $off-black;
    }
  }
}

.editor {
  counter-reset: line;
  border: 1px solid #ced4da;
}

.editor #codeArea {
  outline: none;
  padding-left: 60px !important;
}

.editor pre {
  padding-left: 60px !important;
}

.editor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #cccccc;
  text-align: right;
  width: 40px;
  font-weight: 100;
}

.new-topic-tile {
  height: auto;
}

.dialog-button-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.base-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  box-shadow: $standard-shadow;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    box-shadow: $dark-shadow;
  }
}

#showreels {
  background: white;
  height: $fullscreen-subtract-header;
  display: flex;
  flex-direction: column;

  .showreels-top-container {
    border-bottom: 1px solid lightgray;
    height: $fullscreen-subtract-header-div2;
  }

  .showreels-bottom-container {
    height: $fullscreen-subtract-header-div2;
  }
}

#showreels-content {
  overflow-y: auto;
  height: $fullscreen-subtract-header-div2;
  padding: 20px;
  border-right: 1px solid lightgray;

  p {
    font-size: 12px;
    color: $off-black;
  }

  hr {
    margin: 0px;
  }

  h2 {
    font-size: 16px;
    margin: 0px 0px 5px 0px;
  }

  .small-icon {
    height: 25px;
    width: 25px;
  }

  .base-tile {
    width: 100px;
    height: 80px;
    color: black;
    font-size: 12px;
  }

  .showreels-content-breadcrumbs {
    margin-bottom: 15px;
  }

  .showreels-content-list-item {
    padding: 15px 0px;

    &:hover {
      cursor: pointer;
      background: #fafafa;
    }

    &.non-media {
      color: lightgray;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }

  .showreels-content-tile-container {
    p {
      color: white;
    }

    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .content-medium {
      background: linear-gradient(0deg, $off-black, #474747);
      color: white;
      width: 100px;
      height: 100px;
      padding: 10px;
      font-size: 11px;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      .content-medium-logo {
        font-size: 20px;
      }

      &:hover {
        box-shadow: $dark-shadow;
      }
    }
  }

  .showreels-content-tile {
    position: relative;
    margin-left: 5px;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  .showreels-content-tile-layover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: none;
    z-index: 10;
  }

  .showreels-content-video {
    min-width: 120px;
    max-width: 200px;
    height: 100px;
    border-radius: 5px;
    z-index: -1;

    &.clone {
      width: 150px;
      border: 1px dashed lightgray;
      color: lightgray;
    }
  }

  .showreels-content-audio {
    text-align: center;
    margin-bottom: 10px;
  }

  .showreels-content-audio-container {
    padding: 20px 10px;
    background: $off-black;
    margin: 5px;
    color: white;
    border-radius: 5px;
    box-shadow: $standard-shadow;

    p {
      margin: 0;
      font-size: 12px;
    }
  }
}

#showreels-preview {
  height: $fullscreen-subtract-header-div2;
  background-color: $off-black;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;

  .showreels-preview-video {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 50px);
  }

  .showreel-preview-controls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
    font-size: 20px;
    border-top: 1px solid gray;
    background: white;
    color: rgb(49, 49, 49);

    .showreel-preview-control-button-absolute {
      &.left {
        position: absolute;
        left: 10px;
        bottom: 0;
        height: 50px;
        z-index: 10;
      }

      &.right {
        position: absolute;
        right: 10px;
        bottom: 0;
        height: 50px;
      }
    }

    .showreel-preview-control-button {
      height: 35px;
      padding: 0px 5px;
      margin: 0px 3px;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }

      &.smaller {
        font-size: 16px;
      }

      .disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    border-bottom: solid 1px lightgray;
  }

  .showreel-preview-trimming {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 100;
  }

  .microphone-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 50;
    color: white;
    font-size: 40px;
  }

  .title-overlay {
    position: absolute;
    display: table-cell;
    vertical-align: bottom;
    top: 0;
    width: 100%;
    height: calc(100% - 50px);
    z-index: 50;
    color: white;
    font-size: 40px;
    text-align: center;
  }

  .title-overlay-text {
    width: 100%;
    padding: 30px 45px;
  }

  .h-right .title-overlay-text {
    position: absolute;
    right: 0;
    text-align: right;
  }

  .h-left .title-overlay-text {
    position: absolute;
    left: 0;
    text-align: left;
  }

  .v-center .title-overlay-text {
    position: absolute;
    top: 25%;
  }

  .v-bottom .title-overlay-text {
    position: absolute;
    bottom: 0;
  }

  .v-top .title-overlay-text {
    position: absolute;
    top: 0;
  }

  .transcription-overlay {
    position: absolute;
    bottom: 70px;
    max-width: 70%;
    z-index: 50;
    color: white;
    font-size: 14px;
    padding: 0px 40px;
    text-shadow: 1px 1px 2px black;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    text-align: center;
  }

  .showreel-watermark {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      max-width: 100px;
      max-height: 50px;

      &.phonic-watermark {
        opacity: 0.75;
      }
    }
  }

  .showreel-demographics {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(128, 128, 128, 0.507);
    max-width: 150px;
    padding: 5px;

    p {
      font-size: 10px;
      color: white;
      margin: 0;
    }
  }

  .MuiSlider-thumb:not(.MuiSlider-active) {
    transition: left 0.15s ease-in;
  }

  .MuiSlider-track {
    transition: width 0.15s ease-in;
  }
}

#showreels-timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  overflow-x: scroll;

  .showreels-timeline-block {
    position: relative;
    border-radius: 5px;
    margin-right: 15px;
  }

  .showreels-timeline-tile-layover {
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1000;
  }

  .showreels-timeline-tile-layover.right {
    right: 0;
  }

  .showreels-timeline-tile-layover.left {
    left: 0;
  }

  .showreels-timeline-placeholder-content {
    height: 175px;
    width: 200px;
    padding: 20px;
    border: 1px dashed lightgray;
    border-radius: 5px;
    color: lightgray;
  }

  .showreels-timeline-content {
    min-width: 200px;
    max-width: 400px;
    height: 170px;
    border-radius: 5px;
    z-index: -1;

    &.dimmed {
      opacity: 0.5;
    }

    &.highlighted {
      box-shadow: 0 0 3pt 2pt $phonic-green;
      opacity: 1;
    }

    &.title-slide,
    &.audio {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $off-black;
      color: white;
      font-size: 30px;
      height: 175px;
      padding: 20px;

      p {
        margin: 0;
        font-size: 12px;
      }

      h2 {
        text-align: center;
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 5px;
      }

      h3 {
        text-align: center;
        margin-top: 0px;
        font-size: 12px;
      }
    }

    &.title-slide {
      width: 210px;

      .MuiInputBase-input {
        color: white !important;
      }
    }
  }
}

.showreels-time-container {
  color: $off-black;
  margin-top: 20px;

  .time-container {
    padding: 5px 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
}

#showreels-subtitles-popup {
  .subtitles-container {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 16px;

    &.disabled {
      background: lightgray;
    }
  }

  .transcription-text.large {
    font-size: 14px;
  }
}

#showreels-share {
  h1 {
    font-size: 36px;
  }

  video {
    max-width: 100%;
    max-height: 1000px;
    border-radius: 10px;
    box-shadow: $standard-shadow;
  }

  .inner-container {
    .showreel-header {
      position: relative;
    }
  }
}

@media (max-width: 600px) {
  #showreels-content {
    display: none;
  }
}

#sidebar .nav {
  overflow-y: auto;
  padding-bottom: 60px;
  text-align: center;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#recruit {
  height: $fullscreen-subtract-header;
  width: 100%;

  h3 {
    font-size: 16px;
    margin: 0 0 20px 0;
    font-weight: bold;
  }

  hr {
    margin: 10px 0px;
  }

  #demographics-filtering-pane,
  #audience-pane {
    background: #fafafa;
    padding: 20px;
    border-right: solid 1px lightgray;
  }

  #demographics-filtering-pane {
    background: white;

    h3 {
      margin: 0px 0px 10px 0px;
    }
  }

  #options-pane {
    padding: 20px 10px 20px 20px;
    height: $fullscreen-subtract-header;
    overflow-y: scroll;
  }

  #pricing-pane {
    padding: 20px 20px 20px 10px;

    ul {
      padding-inline-start: 20px;
      font-size: 12px;
      margin: 15px 0px;
    }

    td {
      font-size: 12px;
    }

    .total-price {
      color: $off-black;
      font-size: 16px;
    }

    .dollar-sign {
      color: gray;
      font-size: 14px;
      padding-top: -20px;
    }
  }

  .recruit-container {
    height: 100%;
  }

  .audience-tile {
    background: white;
    position: relative;
    border: 1px solid lightgray;
    border-left: 4px solid lightgray;
    border-radius: 8px;
    min-height: 100px;
    padding: 15px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      box-shadow: $standard-shadow;
    }

    &.selected {
      border-left: 4px solid $phonic-green;
    }
  }

  .recruit-paper {
    padding: 20px;
    margin-bottom: 20px;

    .selected-options {
      margin-bottom: 10px;
    }
  }

  .add-audience-button {
    margin: 0px auto;
    width: 40px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: gray;
    cursor: pointer;

    &:hover {
      box-shadow: $standard-shadow;
    }
  }
}

.bounded-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgb(230, 230, 230);
  padding: 3px 10px;
  padding-left: 2px; // Checkbox already has padding
  border-radius: 3px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
  min-width: 100px;

  &.checked {
    background: #28bf8f2d;

    .MuiSvgIcon-root {
      color: $phonic-green !important;
    }
  }
}

.footer {
  background-color: #fff;
  line-height: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.MuiButton-contained.deploy-button {
  float: right;
  width: 120px;
}

.create-report-wrapper {
  height: calc(100vh - 125px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.bookmark-dialog-title {
  margin-top: 10;
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 300;
}

.hover-menu {
  position: absolute;
  top: -35px;
  left: 10px;
  background: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    box-shadow: $standard-shadow;
  }
}

.highlighted-transcription {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.file-viewer-grid {
  position: relative;
  background-color: #fafafa;
}

.context-banner {
  @include flex(row, flex-end, center);
  padding: 0 36px 0 24px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 60px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

#file-viewer {
  background: #fafafa;
  padding: 24px 24px;
  height: calc(100vh - 85px);
  overflow-y: auto;

  .demographics-wrapper {
    padding: 10px;
    border: 1px solid lightgray;
    background: white;
    border-radius: 8px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .edit-icon {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .nav-path {
    margin-bottom: 15px;
  }

  .nav-path .directory {
    padding: 0 5px;
    border-radius: 5px;

    &:hover {
      background-color: rgba(233, 233, 233, 0.6);
      cursor: pointer;
    }
  }
}

.floating-media {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 340px;
  margin: 0 16px 16px 0;
  z-index: 1000;
}

.skeleton {
  background-color: rgba(255, 255, 255, 0.11);
  transform: scale(1, 0.6);
  border-radius: 4px;
  margin-bottom: 0;
}

.auto-refresh-toggle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  font-size: 12px;
  color: #595c62;
  vertical-align: middle;
}

.auto-refresh-toggle-label {
  padding-right: 8px;
}

.embed-options {
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.code-block {
  cursor: text;
  max-height: 150px;
  white-space: pre-wrap;
}

.color-preview-tile {
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: 0 2px;
  box-shadow: $standard-shadow;
  width: 32px;
  height: 32px;
}

.color-preview-wrapper {
  @include flex(row, flex-start, center);
  margin: 8px 0;

  >*:first-child {
    margin-right: 8px;
  }
}

.triangle {
  position: absolute;
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 100% 0);
  right: 4px;
  bottom: 4px;
  width: 5px;
  height: 5px;
}

.phonic-square-chip {
  padding: 10px 10px;
  background: #28bf8f2d;
  display: flex;
  align-items: center;
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  .delete-icon {
    opacity: 0.75;
    color: $phonic-green;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.sidebar-profile-icon {
  position: fixed;
  bottom: 10px;
  left: 12px;
  z-index: 100;
}

.search-container {
  display: inline-block;
  background: white;
  border: 1px solid #f0f0f0;
  padding: 5px;
  padding-left: 10px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    border: 1px solid #b6b6b6;
  }
}

.navbar-search-content {
  display: table-cell;
  vertical-align: middle;
}

.MuiChip-root.tier-container {
  cursor: pointer;

  &.free {
    background-color: gray;

    &:hover {
      background-color: rgb(212, 212, 212);
    }
  }

  &.premium {
    background-color: #22ce81;

    &:hover {
      background-color: #22ce81;
    }
  }
}

.selected-session-row,
.selected-session-row:hover {
  background-color: #d3eae5 !important;
}

.panel-request-chip {
  color: white !important;

  &.draft {
    background-color: gray;
  }

  &.review {
    background-color: #578fc0;
  }

  &.collecting {
    background-color: #d6c847;
  }

  &.completed {
    background-color: $phonic-green;
  }
}

.checkout {
  display: flex;
  justify-items: center;
  padding: 20px !important;

  .checkout-inner {
    width: 475px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;

    h1 {
      text-align: center;
      font-size: 24px;
      color: $off-black;
      margin: 0px 0px 20px 0px;
    }

    h3 {
      text-align: center;
      font-size: 16px;
      color: gray;
      margin: 10px 0px;
    }

    .checkout-header {
      padding: 11px;
    }

    .checkout-row {
      margin: 30px auto;
      box-shadow: $standard-shadow;
      border-radius: 4px;
      background-color: white;
      position: relative;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .form-field-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #fafafa;

    &:first-of-type {
      border-top: none;
    }

    label {
      width: 20%;
      min-width: 70px;
      padding: 11px 0;
      overflow: hidden;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: 1px solid #fafafa;
      margin-bottom: 0px;
    }

    input {
      font-size: 14px;
      width: 100%;
      padding: 11px 15px 11px 8px;
      background-color: transparent;
      animation: 1ms void-animation-out;
      border: none;
    }
  }
}

#checkout-completed-animation {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.video-player {
  max-width: 100%;
  border-radius: 8px;

  &.small {
    min-width: 200px;
  }

  &.full-width {
    width: 100%;
  }
}

.video-player-container {
  min-width: 300px;
}

.card-element-container {
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
}

#results-screen {
  height: $fullscreen-subtract-header;
  display: flex;
  flex-direction: column;

  #results-tabs-container {
    width: 100%;
    position: relative;

    .action-buttons {
      display: flex;
      position: absolute;
      right: 30px;
      top: 7px;
    }
  }

  #results-tabs {
    width: 100%;
    background: white;
  }

  #results-screen-inner {
    overflow: hidden;
    flex-grow: 1;

    #left-results-pane {
      float: left;
      width: $left-results-pane-width-l;
      height: 100%;
      background: white;
      border-right: 1px solid lightgray;

      @media (max-width: 1350px) {
        width: $left-results-pane-width-m;
      }

      @media (max-width: 991px) {
        width: $left-results-pane-width-s;
      }
    }

    #right-results-pane {
      float: right;
      height: 100%;
      width: calc(100% - $left-results-pane-width-l);
      margin: 0px;
      padding: 30px;
      overflow-y: scroll;

      @media (max-width: 1350px) {
        width: calc(100% - $left-results-pane-width-m);
      }

      @media (max-width: 991px) {
        width: calc(100% - $left-results-pane-width-s);
      }

      #title-block {
        display: flex;
        overflow: hidden;
        width: 100%;
        margin-bottom: 20px;

        .title-block-text {
          width: calc(100% - 60px);

          .question-title {
            margin: 0px;
            font-size: 24px;
            color: $off-black;
            font-weight: 500;

            @media (max-width: 1350px) {
              font-size: 16px;
            }
          }

          .question-subtitle {
            margin: 0px;
            font-size: 16px;
            color: gray;

            @media (max-width: 1350px) {
              font-size: 12px;
            }
          }
        }
      }

      #question-analytics {
        margin-bottom: 10px;
      }
    }
  }

  #completion-screen {
    padding: 20px 20px 0px 20px;
    height: 100%;
    overflow-y: scroll;
  }

  #share-screen {
    padding: 20px 20px 0px 20px;
    height: 100%;
    overflow-y: scroll;
  }

  .dummy-results-tile {
    padding: 20px;
    height: 80px;
  }
}

.results-tile {
  padding: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.selected,
  &:hover {
    background: lightgray;
  }

  &.slim {
    height: 60px;
  }

  .results-tile-left {
    margin-right: 10px;
  }

  .results-tile-right {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    flex-grow: 1;

    .results-tile-text {
      font-size: 14px;
      color: $off-black;

      @media (max-width: 1350px) {
        font-size: 12px;
      }
    }

    .results-tile-subtext {
      font-size: 12px;
      color: gray;

      @media (max-width: 1350px) {
        font-size: 11px;
      }
    }
  }

  .results-tile-icon {
    color: gray;
  }
}

#builder-appearance-tab {
  padding: 50px;
  height: 100%;
}

.tooltip-title {
  h2 {
    font-size: 12px;
    margin: 0px 0px 2px 0px;
  }

  h3 {
    font-size: 11px;
    margin: 0;
  }
}

@media (max-width: 600px) {

  #saving-spinner,
  #unpublished-alert,
  #duration-estimate {
    display: none !important;
  }
}

.likert {
  overflow: auto;
  max-height: 650px;

  table {
    border-collapse: collapse;
    margin: 0 auto;

    thead th {
      position: sticky;
      top: 0px;
      z-index: 5;
      background-color: #f9f9f9;
      min-width: 80px;

      &:first-child {
        left: 0px;
        z-index: 10;
        min-width: 0px;
        text-align: left;
      }
    }

    tbody th {
      position: sticky;
      left: 0;
      z-index: 5;
      background-color: #f9f9f9;
      min-width: 80px;
      text-align: left;

      &:first-child {
        min-width: 0px;
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid rgb(233, 233, 233);
    }

    th,
    td {
      text-align: center;
      vertical-align: middle;
      padding: 8px;
      font-weight: 400;
      font-family: "Inter", "Helvetica Neue", Arial, sans-serif;
    }
  }
}

.keyboard-icon-container {
  border: solid 2px gray;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &:hover {
    color: gray;
    border: solid 1px lightgray;
  }
}

.phonic-green-hover:hover {
  box-shadow: 0 1px 6px 0 $phonic-green;
  border: 1px solid $phonic-green;
}

.template-survey-new-outer {
  border: solid 1px lightgray;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  width: 300px;
  overflow: hidden;

  .expandable {
    overflow: hidden;
    transition: height 400ms;
    height: 0px;

    &.expanded {
      padding-top: 10px;
      height: 110px;
    }
  }

  .expandable-large {
    overflow: hidden;
    transition: height 400ms;
    height: 0px;

    &.expanded {
      padding-top: 10px;
      height: 160px;
    }
  }
}

.template-survey-new {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: left;
  color: $off-black;

  .template-survey-new-left {
    margin-right: 10px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .template-survey-new-right {
    flex-grow: 1;

    h3 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      margin: 0;
    }
  }
}

.template-survey-tile {
  background-color: white;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #4c4c4c;
  transition: 0s !important;
  min-width: 130px;
  text-align: left;
  width: 250px;
  height: 250px;

  &:hover {
    border: 1px solid $phonic-green;
    box-shadow: 0 1px 6px 0 $phonic-green;
  }

  h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
}

#builder-popup {
  #builder-popup-body {
    display: flex;
    background: #fafafa;
    height: 75vh;

    #builder-popup-left {
      width: 240px;
      border-right: 1px solid lightgray;
      padding: 0px 20px;

      #category-container {
        width: 200px;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    #builder-popup-right {
      flex-grow: 1;
      padding: 0px 20px;
      overflow: scroll;
    }
  }

  h3 {
    margin-top: 0px;
  }

  .root {
    left: 10;
    top: 50;
    border-radius: 15px;
  }

  #preview-container {
    display: flex;
    flex-direction: column;
  }

  .template-category-wrapper {
    margin: 15px 0px 15px 0px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  .no-results-text {
    font-size: 18px;
    text-align: center;
    margin-top: 5%;
  }

  .info-column {
    background-color: #fafafa;
  }

  #question-overview {
    background: white;
    border: lightgray 1px solid;
    border-radius: 5px;
    padding: 10px;
    max-height: 350px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .question-overview-element {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      border-bottom: solid 1px lightgray;

      .question-overview-icon {
        margin-right: 0px;
      }

      p {
        margin: 0;
        font-size: 13px;
        color: gray;
      }
    }
  }

  .descriptor-text {
    font-size: 12px;
    margin-bottom: 0px;
    color: gray;
  }

  .template-category {
    color: gray;
  }

  .template-list {
    margin-bottom: 30px;
  }
}

.counted-category {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-left: -5px;
  margin-right: -5px;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  .selected {
    background: lightgray;
  }

  .counted-category-left {
    flex-grow: 1;
  }

  .counted-category-right {
    width: 20px;
    height: 20px;
    background: $phonic-green-transparent;
    border-radius: 3px;
    font-size: 10px;
    color: $off-black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}

#template-summary {
  width: 100%;
  display: flex;

  #template-summary-left {
    position: relative;
    width: 350px;
    border-right: 1px solid lightgray;

    .button-container {
      position: absolute;
      bottom: 10px;
      width: 100%;
      padding: 10px;
    }
  }

  #template-summary-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    #preview {
      width: 100%;
      flex-grow: 1;
      border: none;
      border-bottom: 1px solid lightgray;
    }

    #description {
      min-height: 150px;
      width: 100%;
      padding: 20px;

      h2 {
        font-size: 24px;
        margin: 10px 0px 5px 0px;
      }

      p {
        font-size: 14px;
        margin: 0px 0px 5px 0px;
      }
    }
  }
}

#survey-creation-drafts-screen {
  width: 100%;
  padding: 20px;

  h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
  }

  #drafts-table {
    border: solid 1px lightgray;
    border-radius: 8px;
    overflow-y: auto;
    width: 100%;
    background: white;
  }
}

// Dot textured background.
$dot-bg-color: white;
$dot-point-color: #8a8a8a;
$dot-size: 1px;
$dot-space: 16px;

.dots {
  background: linear-gradient(90deg,
      $dot-bg-color (
      $dot-space - $dot-size),
    transparent 1%) center,
    linear-gradient($dot-bg-color (
      $dot-space - $dot-size),
    transparent 1%) center,
    $dot-point-color;
  background-size: $dot-space $dot-space;
}

#home-screen {
  min-height: $fullscreen-subtract-header;
  width: 100%;

  h2 {
    font-size: 24px;
    margin: 0;
    padding: 10px 0px 10px 0px;
    color: $off-black;
    text-align: center;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  p {
    font-size: 12px;
  }

  #tabs {
    background: white;
  }
}

#onboarding {
  padding: 20px;
}

.onboarding-card {
  background: white;
  position: relative;
  border-radius: 8px;
  width: 250px;
  height: 400px;
  margin: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.inactive {
    opacity: 0.5;
  }

  &:hover {
    box-shadow: $standard-shadow;
  }

  h3 {
    font-size: 18px;
  }

  .onboarding-image {
    width: 120px;
    height: 120px;
  }

  .button-tray {
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
}

.home-screen-tile {
  text-align: center;
  position: relative;
  padding-bottom: 80px;

  h3 {
    font-weight: 500;
  }

  img {
    display: inline-block;
    width: 120px;
    height: 120px;
  }

  .button-tray {
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
}

.speaker-avatar-container {
  font-size: 11px;
  -webkit-user-select: none !important;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  margin-bottom: 5px;
}

.speaker-avatar {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}

.files-usage-container {
  text-align: center;

  .files-usage-text {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .files-usage-subtext {
    font-size: 10px;
    color: gray;

    &:hover {
      text-decoration: underlined;
      cursor: pointer;
    }
  }
}

.plan-increment-container {
  min-width: 40px;
}

.plan-increment {
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

#upgrade-plan-popup {
  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  h3 {
    text-align: center;
    margin-top: 0px;
  }

  p {
    font-size: 12px;
  }
}