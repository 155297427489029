@import "./variables";

.basic-chart {
  width: 300px;
}

.chart-container {
  text-align: center;
  padding: 10px;
  /* so absolutely positioned top-hamburger anchors to this element */
  position: relative;
  box-shadow: $standard-shadow;
  border-radius: 8px;
  background: white;
}

.custom-chart-container {
  position: relative;
  display: inline-block;
  width: 100%;
  .contents {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .dummy {
    margin-top: 50%;
  }
}
