.text-sm {
  font-size: 12px;
}
.text-md {
  font-size: 18px;
}
.text-lg {
  font-size: 24px;
}
.float-right {
  float: right;
}
.width-100p {
  width: 100%;
}
.width-50p {
  width: 50%;
}
.height-100p {
  height: 100%;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.fg-1 {
  flex-grow: 1;
}
.m-0 {
  margin: 0px;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 30px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-30 {
  margin-right: 30px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.fw-500 {
  font-weight: 500;
}

.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.link-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.pos-relative {
  position: relative;
}
.dis-inline-block {
  display: inline-block;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#container {
  display: inline-block;
  position: relative;
  width: 50%;
}

.top-right {
  position: absolute;
  top: 0px;
  right: 0px;
}

.gray-text {
  color: gray;
}

.square-max-md {
  max-width: 250px;
  max-height: 250px;
}
.square-max-sm {
  max-width: 150px;
  max-height: 150px;
}
.background-white {
  background: white;
}
.display-ilb {
  display: inline-block;
}

.bottom-border {
  border-bottom: 1px #dadada solid !important;
}
