$text-color: rgb(43, 43, 43);

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  float: left;
  width: 85px;
  display: block;
  z-index: 1;
  color: $text-color;
  font-weight: 200;
  background-size: cover;
  background-position: center center;

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 50px);
    overflow: auto;
    width: 85px;
    z-index: 4;
    padding-bottom: 30px;
    background-color: #1d1c29;
    display: flex;
    justify-content: center;
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .logo {
    padding-top: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 7;

    a.logo-mini {
      float: left;
      text-align: center;
      width: 30px;
      margin-right: 15px;

      img {
        width: 40px;
        margin-left: -3px;
        display: block;
        margin-top: 2px;
      }
    }

    a.logo-normal {
      display: block;
    }

    .logo-img {
      width: 34px;
      display: inline-block;
      height: 34px;
      margin-left: -2px;
      margin-top: -2px;
      margin-right: 10px;
      border-radius: 30px;
      text-align: center;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    li {
      > a {
        color: #ffffff;
        margin: 10px 10px;
        opacity: 0.86;
        border-radius: 8px;
      }

      &:hover > a,
      &.open > a,
      &.open > a:focus,
      &.open > a:hover {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
      }

      &.active > a {
        color: #ffffff;
        opacity: 1;
        background: rgba(255, 255, 255, 0.23);
      }
    }

    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }

    i {
      font-size: 28px;
      float: left;
      line-height: 30px;
      width: 100%;
      text-align: center;
    }
  }
}

.sidebar,
body > .navbar-collapse {
  .logo {
    padding-top: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      font-weight: $font-weight-normal;
      line-height: 30px;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: $sidebar-width;

  > .content {
    padding: 15px 15px;
    min-height: calc(100vh - 60px);
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
  }
}

.sidebar,
.main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}
